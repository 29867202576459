var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "right",
          "padding-bottom": "15px",
          "border-bottom": "1px solid #ebeef5",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { loading: _vm.saveLoading, type: "success" },
            on: {
              click: function ($event) {
                return _vm.isSave(false)
              },
            },
          },
          [_vm._v("上架")]
        ),
        _c(
          "el-button",
          {
            attrs: { loading: _vm.saveLoading, type: "primary" },
            on: {
              click: function ($event) {
                return _vm.isSave(true)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("button.preservation")))]
        ),
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bgFFF" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticStyle: { padding: "20px 0" },
            attrs: { model: _vm.form, "label-width": "120px" },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "标题:" } },
                      [
                        _c("el-input", {
                          attrs: {
                            rows: 2,
                            type: "textarea",
                            placeholder: "请输入投放广告的主题内容",
                            maxlength: "64",
                            "show-word-limit": "",
                          },
                          model: {
                            value: _vm.form.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "title", $$v)
                            },
                            expression: "form.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "分类：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "categoryId", $$v)
                              },
                              expression: "form.categoryId",
                            },
                          },
                          _vm._l(_vm.categoryList, function (item) {
                            return _c("el-option", {
                              key: item.categoryId,
                              attrs: {
                                label: item.name,
                                value: item.categoryId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "区域：" } },
                      [
                        _c("el-cascader", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            options: _vm.cityList,
                            props: _vm.props,
                            "show-all-levels": false,
                            placeholder: "请选择",
                            "collapse-tags": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.areaIds,
                            callback: function ($$v) {
                              _vm.areaIds = $$v
                            },
                            expression: "areaIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "展示模块：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "请选择" },
                            on: { change: _vm.getDisplayPositionList },
                            model: {
                              value: _vm.moduleId,
                              callback: function ($$v) {
                                _vm.moduleId = $$v
                              },
                              expression: "moduleId",
                            },
                          },
                          _vm._l(_vm.moduleList, function (item) {
                            return _c("el-option", {
                              key: item.moduleId,
                              attrs: {
                                label: item.terminal + "->" + item.moduleName,
                                value: item.terminal + "->" + item.moduleName,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "展示位：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  _vm.form.moduleId,
                                  $event
                                )
                              },
                            },
                            model: {
                              value: _vm.form.moduleId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "moduleId", $$v)
                              },
                              expression: "form.moduleId",
                            },
                          },
                          _vm._l(_vm.displayPositionList, function (item) {
                            return _c("el-option", {
                              key: item.moduleId,
                              attrs: {
                                label: item.location,
                                value: item.moduleId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "block" },
                        attrs: { label: "广告图片:" },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            class: { hide: _vm.hideUploadEdit },
                            attrs: {
                              "on-remove": _vm.handleRemove,
                              "on-exceed": _vm.handleExceed,
                              "before-upload": _vm.beforeUpload,
                              "on-success": _vm.handleSuccess,
                              "on-change": _vm.handleEditChange,
                              limit: 1,
                              "file-list": _vm.fileList,
                              name: "image",
                              headers: _vm.uploadHeader,
                              action: "/acb/2.0/advert/upload",
                              data: _vm.uploadData,
                              "list-type": "picture-card",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [
                                _vm._v(
                                  " 请上传图片宽高比不限，大小5M以内，支持格式.jpg .png .gif .bmp "
                                ),
                              ]
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.fileList.length < 1,
                                  expression: "fileList.length < 1",
                                },
                              ],
                              staticClass: "el-icon-plus",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "跳转类型:" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.changeJumpUrlType },
                            model: {
                              value: _vm.form.jumpUrlType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "jumpUrlType", $$v)
                              },
                              expression: "form.jumpUrlType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("页面URL"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("应用路径"),
                            ]),
                          ],
                          1
                        ),
                        _vm.form.jumpUrlType === 1
                          ? _c("el-input", {
                              attrs: {
                                placeholder:
                                  "跳转落地页url链接(http://)，不填写时，不跳转",
                                maxlength: "255",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.jumpUrl,
                                callback: function ($$v) {
                                  _vm.jumpUrl = $$v
                                },
                                expression: "jumpUrl",
                              },
                            })
                          : _vm._e(),
                        _vm.form.jumpUrlType === 2
                          ? _c("el-cascader", {
                              staticStyle: { display: "block" },
                              attrs: {
                                options: _vm.jumpOptions,
                                props: {
                                  expandTrigger: "hover",
                                  checkStrictly: true,
                                },
                              },
                              model: {
                                value: _vm.jumpUrlList,
                                callback: function ($$v) {
                                  _vm.jumpUrlList = $$v
                                },
                                expression: "jumpUrlList",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form",
                      { attrs: { inline: true, "label-width": "120px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "展示时间:" } },
                          [
                            _vm._l(_vm.timeArrays, function (item, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "margin-bottom": "10px",
                                      "background-color": "#f0f0f0",
                                      padding: "6px",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      key: index + ",",
                                      attrs: {
                                        type: "daterange",
                                        "range-separator": "~",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                        "picker-options": _vm.expireTimeOption,
                                      },
                                      model: {
                                        value: item.datePick,
                                        callback: function ($$v) {
                                          _vm.$set(item, "datePick", $$v)
                                        },
                                        expression: "item.datePick",
                                      },
                                    }),
                                    _c("el-time-picker", {
                                      key: index + ".",
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        "is-range": "",
                                        "range-separator": "~",
                                        "value-format": "HH:mm",
                                        format: "HH:mm",
                                        "start-placeholder": "开始时间",
                                        "end-placeholder": "结束时间",
                                      },
                                      model: {
                                        value: item.timePick,
                                        callback: function ($$v) {
                                          _vm.$set(item, "timePick", $$v)
                                        },
                                        expression: "item.timePick",
                                      },
                                    }),
                                    _c("i", {
                                      key: index + "/",
                                      staticClass:
                                        "el-icon-circle-plus custom-icon-css",
                                      on: { click: _vm.createNewTime },
                                    }),
                                    index > 0
                                      ? _c("i", {
                                          key: index + ";",
                                          staticClass:
                                            "el-icon-remove-outline custom-icon-css remove",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteTime(index)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }