<template>
  <div>
    <div style="text-align: right; padding-bottom: 15px; border-bottom: 1px solid #ebeef5">
      <el-button :loading="saveLoading" type="success" @click="isSave(false)">上架</el-button>
      <el-button :loading="saveLoading" type="primary" @click="isSave(true)">{{ $t('button.preservation') }}</el-button>
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="bgFFF">
      <el-form ref="form" :model="form" label-width="120px" style="padding: 20px 0">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="标题:">
              <el-input
                v-model="form.title"
                :rows="2"
                type="textarea"
                placeholder="请输入投放广告的主题内容"
                maxlength="64"
                show-word-limit
              />
            </el-form-item>
            <el-form-item label="分类：">
              <el-select v-model="form.categoryId" clearable placeholder="请选择">
                <el-option
                  v-for="item in categoryList"
                  :key="item.categoryId"
                  :label="item.name"
                  :value="item.categoryId"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="区域：">
              <el-cascader
                :options="cityList"
                :props="props"
                :show-all-levels="false"
                v-model="areaIds"
                style="width: 100%"
                placeholder="请选择"
                collapse-tags
                clearable
              />
            </el-form-item>
            <el-form-item label="展示模块：">
              <el-select
                v-model="moduleId"
                clearable
                placeholder="请选择"
                style="width: 100%"
                @change="getDisplayPositionList"
              >
                <el-option
                  v-for="item in moduleList"
                  :key="item.moduleId"
                  :label="item.terminal + '->' + item.moduleName"
                  :value="item.terminal + '->' + item.moduleName"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="展示位：">
              <el-select
                v-model="form.moduleId"
                clearable
                placeholder="请选择"
                @change="$set(form, form.moduleId, $event)"
              >
                <el-option
                  v-for="item in displayPositionList"
                  :key="item.moduleId"
                  :label="item.location"
                  :value="item.moduleId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="广告图片:" style="display: block">
              <el-upload
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                :before-upload="beforeUpload"
                :on-success="handleSuccess"
                :on-change="handleEditChange"
                :limit="1"
                :file-list="fileList"
                name="image"
                :headers="uploadHeader"
                action="/acb/2.0/advert/upload"
                :data="uploadData"
                list-type="picture-card"
                :class="{ hide: hideUploadEdit }"
              >
                <div slot="tip" class="el-upload__tip">
                  请上传图片宽高比不限，大小5M以内，支持格式.jpg .png .gif .bmp
                </div>
                <i v-show="fileList.length < 1" class="el-icon-plus" />
              </el-upload>
            </el-form-item>
            <el-form-item label="跳转类型:">
              <el-radio-group v-model="form.jumpUrlType" @change="changeJumpUrlType">
                <el-radio :label="1">页面URL</el-radio>
                <el-radio :label="2">应用路径</el-radio>
              </el-radio-group>
              <el-input
                v-if="form.jumpUrlType === 1"
                v-model="jumpUrl"
                placeholder="跳转落地页url链接(http://)，不填写时，不跳转"
                maxlength="255"
                show-word-limit
              />
              <el-cascader
                v-if="form.jumpUrlType === 2"
                v-model="jumpUrlList"
                :options="jumpOptions"
                :props="{ expandTrigger: 'hover', checkStrictly: true }"
                style="display: block"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form :inline="true" label-width="120px">
              <el-form-item label="展示时间:">
                <template v-for="(item, index) in timeArrays">
                  <div
                    :key="index"
                    style="margin-bottom: 10px; background-color: #f0f0f0; padding: 6px"
                  >
                    <el-date-picker
                      :key="index + ','"
                      v-model="item.datePick"
                      type="daterange"
                      range-separator="~"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                    <el-time-picker
                      :key="index + '.'"
                      is-range
                      v-model="item.timePick"
                      range-separator="~"
                      value-format="HH:mm"
                      format="HH:mm"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      style="margin-left: 10px"
                    >
                    </el-time-picker>
                    <i
                      :key="index + '/'"
                      class="el-icon-circle-plus custom-icon-css"
                      @click="createNewTime"
                    />
                    <i
                      v-if="index > 0"
                      :key="index + ';'"
                      class="el-icon-remove-outline custom-icon-css remove"
                      @click="deleteTime(index)"
                    />
                  </div>
                </template>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      form: { jumpUrlType: 1, title: "", categoryId: "", moduleId: "" },
      types: [],
      cityList: [],
      allCityData: [],
      categoryList: [],
      moduleId: "",
      moduleList: [],
      displayPositionList: [],
      jumpUrl: "",
      jumpUrlList: [],
      areaIds: [],
      props: {
        value: "areaId",
        label: "areaName",
        expandTrigger: "hover",
        multiple: true,
      },
      cityNames: [],
      fileList: [],
      gridData: [],
      reflesh: true,
      length: 0,
      saveLoading: false,
      timeArrays: [
        {
          datePick: ["", ""],
          timePick: ["", ""],
        },
      ],
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {},
      jumpOptions: [],
      advertDetail: {},
      expireTimeOption: {
        disabledDate(date) {
          // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      hideUploadEdit: false,
    };
  },
  watch: {
    jumpUrl(val) {
      this.form.jumpUrl = val;
    },
    jumpUrlList(val) {
      if (!val) {
        return;
      }
      delete this.form.jumpUrl;
      if (val.length > 0) {
        this.form.jumpUrl = val[val.length - 1];
      }
    },
    areaIds(val) {
      if (!val || val.length === 0) {
        return;
      }
      const ids = [];
      val.forEach((item) => {
        let newItem = "";
        if (typeof item === "string") {
          newItem = parseInt(item);
        } else {
          newItem = parseInt(item[0]);
        }
        ids.push(newItem);
      });
      this.form.areaIds = ids;
    },
  },
  mounted() {
    if (this.$route.query.advertId) {
      this.uploadData = {
        advertId: this.$route.query.advertId,
      };
      this.getDetailInfo();
    }
    this.getCityList();
    this.getCategoryList();
    this.getModuleList();
  },
  methods: {
    // 获取详情数据
    getDetailInfo() {
      const opt = {
        url: "/acb/2.0/advert/detail/" + this.$route.query.advertId,
        method: "get",
        data: {},
        success: (res) => {
          const result = res.value;
          this.form.advertId = result.advert.advertId;
          this.form.title = result.advert.title;
          this.form.categoryId = result.advert.categoryId;
          this.areaIds = result.areaIds;
          // if (this.areaIds == '') {
          //   this.areaIds = ['']
          // }
          this.moduleId = result.moduleName;
          this.getDisplayPositionList(this.moduleId);
          this.form.moduleId = result.advert.moduleId;
          this.fileList = [{ url: result.advert.imgUrl }];
          this.form.jumpUrlType = result.advert.jumpUrlType;
          if (this.form.jumpUrlType === 1) {
            this.jumpUrl = result.advert.jumpUrl;
          } else if (this.form.jumpUrlType === 2) {
            // this.jumpUrlList = result.advert.jumpUrl
            this.getAppPageList().then(() => {
              // 根据子节点查找所有父节点
              // const targetNode = this.getNode(this.jumpOptions, result.advert.jumpUrl)
              // const getList = [...this.familyTree(this.jumpOptions, targetNode.parentId).reverse(), targetNode.value]
              // this.jumpUrlList = getList
              this.jumpUrlList = result.jumpUrlList;
            });
          }
          this.timeArrays = [];
          if (result.publishList && result.publishList.length > 0) {
            result.publishList.reverse().forEach((item) => {
              const newItem = {
                datePick: [
                  this.$moment(item.startDate).format("YYYY-MM-DD"),
                  this.$moment(item.endDate).format("YYYY-MM-DD"),
                ],
                timePick: [item.lowerTime, item.upperTime],
              };
              this.timeArrays.push(newItem);
            });
          }
          this.hideUploadEdit = this.fileList.length >= 1;
        },
      };
      this.$request(opt);
    },
    changeJumpUrlType() {
      if (this.form.jumpUrlType === 1) {
        return;
      }
      this.getAppPageList();
    },
    getUrl() {
      console.log(this.moduleId);
      let url = "/acb/2.0/advert/appPageList";
      if (
        this.moduleId == "微信小程序->首页轮播" ||
        this.moduleId == "支付宝小程序->首页轮播" ||
        this.moduleId == "微信小程序->启动页" ||
        this.moduleId == "支付宝小程序->启动页"
      ) {
        url = "/acb/2.0/advert/appletPageList";
      }
      return url;
    },
    // 递归筛选出子节点
    // getNode (array, prop) {
    //   if (!array || array.length === 0) {
    //     return
    //   }
    //   let result = ''
    //   array.forEach(item => {
    //     if (item.value === prop) {
    //       result = item
    //     }
    //     if (item.children) {
    //       const newResult = this.getNode(item.children, prop)
    //       if (newResult) {
    //         result = newResult
    //       }
    //     }
    //   })
    //   return result
    // },
    // 递归获取所有父节点
    // familyTree (arr1, parentId) {
    //   const temp = []
    //   const forFn = function (arr, parentId) {
    //     for (let i = 0; i < arr.length; i++) {
    //       const item = arr[i]
    //       if (item.pageId === parentId) {
    //         temp.push(item.value)
    //         forFn(arr1, item.parentId)
    //         break
    //       } else {
    //         if (item.children) {
    //           forFn(item.children, parentId)
    //         }
    //       }
    //     }
    //   }
    //   forFn(arr1, parentId)
    //   return temp
    // },
    // 获取分类列表
    getCategoryList() {
      const opt = {
        url: "/acb/2.0/advertCategory/list",
        method: "get",
        data: {},
        success: (res) => {
          this.categoryList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取投放城市列表
    getCityList() {
      const opt = {
        url: "/acb/2.0/advert/areaList",
        method: "get",
        data: {},
        success: (res) => {
          // let arr = [{
          //    areaName: '全部',
          //    areaId: ''
          // }]
          // arr = arr.concat(res.value)
          this.cityList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取展示模块列表
    getModuleList() {
      const opt = {
        url: "/acb/2.0/advert/moduleList",
        method: "get",
        data: {},
        success: (res) => {
          this.moduleList = res.value;
        },
      };
      this.$request(opt);
    },
    // 获取展示位列表
    getDisplayPositionList(moduleId) {
      this.form.jumpUrlType = 1;
      delete this.form.moduleId;
      this.displayPositionList = [];
      if (!moduleId || !this.moduleList) {
        return;
      }
      const targetList = this.moduleList.filter((item) => {
        return item.terminal + "->" + item.moduleName === moduleId;
      });
      if (targetList.length > 0) {
        const terminal = targetList[0].terminal;
        const moduleName = targetList[0].moduleName;
        const opt = {
          url: "/acb/2.0/advert/locationList",
          method: "get",
          data: {
            terminal: terminal,
            moduleName: moduleName,
          },
          success: (res) => {
            this.displayPositionList = res.value;
          },
        };
        this.$request(opt);
      }
    },
    // 获取图片跳转路径列表
    async getAppPageList() {
      return new Promise((resolve, reject) => {
        const opt = {
          url: this.getUrl(),
          method: "get",
          data: { parentId: 0 },
          success: (res) => {
            const value = res.value;
            this.getFormattedList(value);
            this.jumpOptions = res.value;
            resolve(res.value);
          },
        };
        this.$request(opt);
      });
    },
    // 将数据格式重排
    getFormattedList(value) {
      value.forEach((item) => {
        const keyMap = {
          pagePath: "value",
          pageName: "label",
          childrenList: "children",
        };
        for (const key in item) {
          const newKey = keyMap[key];
          if (newKey) {
            item[newKey] = item[key];
            delete item[key];
          }
        }
        if (item.children) {
          this.getFormattedList(item.children);
        }
      });
    },
    // 验证表单
    checkForm() {
      if (!this.form.title) {
        this.$alert("请输入标题", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (!this.form.categoryId) {
        this.$alert("请选择分类", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (!this.areaIds || this.areaIds.length === 0) {
        this.$alert("请选择投放城市", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (!this.moduleId) {
        this.$alert("请选择展示模块", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (!this.form.moduleId) {
        this.$alert("请选择展示位", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (!this.form.advertId) {
        this.$alert("请上传广告图片", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (this.form.jumpUrlType === 2 && (!this.jumpUrlList || this.jumpUrlList.length === 0)) {
        this.$alert("请选择应用路径", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      if (!this.validateTimeEmpty()) {
        return false;
      }
      if (!this.validateTimeConflict()) {
        this.$alert("展示时间冲突", this.$t('pop_up.Tips'), { confirmButtonText: this.$t('pop_up.Determine') });
        return false;
      }
      return true;
    },
    // 保存
    isSave(isSave) {
      let urlN = "/acb/2.0/advert/advertAdd";
      if (!this.checkForm()) {
        return;
      }
      this.saveLoading = true;
      this.handleTimeArrays();
      if (this.$route.query.advertId) {
        // 编辑
        this.form.operationType = 2;
        this.form.updateUser = sessionStorage.userId;
        urlN = "/acb/2.0/advert/advertUpdate";
      } else {
        // 新增
        this.form.operationType = 1;
        this.form.createUser = sessionStorage.userId;
      }
      if (isSave) {
        this.form.putStatus = 0; // 初始化
      } else {
        this.form.putStatus = 2; // 上架
      }
      //  url: '/acb/2.0/advert/createOrModify',  1 新增 2修改
      const opt = {
        url: urlN,
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: this.form,
        success: (res) => {
          this.saveLoading = false;
          this.$router.push("/advertManage");
        },
        fail: (res) => {
          this.saveLoading = false;
        },
      };
      this.$request(opt);
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.fileList = fileList;
      delete this.form.advertId;
      this.hideUploadEdit = fileList.length >= 1;
    },
    // 超出限制提示
    handleExceed(files, fileList) {
      this.$message.warning("只能上传一张图");
    },
    handleEditChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
    },
    // 上传前的验证
    beforeUpload(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isjpeg = suffix === "gif";
      const isPng = suffix === "png";
      const isBmp = suffix === "bmp";
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isJpg && !isjpeg && !isPng && !isBmp) {
        this.$message.warning("图片格式错误，只支持jpg、gif、bmp和png格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.warning("上传图片大小不能超过 5M!");
        return false;
      }
      return true;
    },
    // 上传成功回调
    handleSuccess(res, file, fileList) {
      this.form.advertId = res.value;
    },
    // 新增时间段
    createNewTime() {
      const item = { datePick: ["", ""], timePick: ["", ""] };
      this.timeArrays.push(item);
    },
    // 删除时间段
    deleteTime(index) {
      this.timeArrays.splice(index, 1);
    },
    // 验证时间非空
    validateTimeEmpty() {
      const newTimeArrays = [...this.timeArrays];
      let flag = true;
      newTimeArrays.forEach((item) => {
        if (!item.datePick || !item.timePick) {
          this.$alert("请将展示时间填写完整", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          flag = false;
        } else if (item.datePick[0] === "" || item.timePick[0] === "") {
          this.$alert("请将展示时间填写完整", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          flag = false;
        }
      });
      return flag;
    },
    // 验证时间冲突
    validateTimeConflict() {
      if (this.timeArrays.length === 0) {
        // 无数据直接返回
        return false;
      }
      let startDateTimeArray = []; // 开始时间的集合
      let endDateTimeArray = []; // 结束时间的集合
      this.timeArrays.forEach((item) => {
        const firstDate = this.$moment(item.datePick[0]).format("YYYY-MM-DD");
        const secondDate = this.$moment(item.datePick[1]).format("YYYY-MM-DD");
        const firstTime = item.timePick[0];
        const secondTime = item.timePick[1];

        const startDateTime = firstDate + " " + firstTime;
        const endDateTime = firstDate + " " + secondTime;
        startDateTimeArray.push(startDateTime);
        endDateTimeArray.push(endDateTime);
        if (firstDate !== secondDate) {
          // 当时间不为同一天
          const secondStartDateTime = secondDate + " " + firstTime;
          const secondEndDateTime = secondDate + " " + secondTime;
          startDateTimeArray.push(secondStartDateTime);
          endDateTimeArray.push(secondEndDateTime);
        }
      });
      startDateTimeArray = startDateTimeArray.sort();
      endDateTimeArray = endDateTimeArray.sort();
      for (let i = 1; i < startDateTimeArray.length; i++) {
        // 判断是否有重合
        if (startDateTimeArray[i] < endDateTimeArray[i - 1]) {
          this.$alert("时间段有重合，请仔细检查", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        }
      }
      return true;
    },
    // 处理时间数组
    handleTimeArrays() {
      this.form.publishList = [];
      this.timeArrays.forEach((item) => {
        const newItem = {
          startDate: this.$moment(item.datePick[0]).format("YYYY-MM-DD"),
          endDate: this.$moment(item.datePick[1]).format("YYYY-MM-DD"),
          lowerTime: item.timePick[0],
          upperTime: item.timePick[1],
        };
        this.form.publishList.push(newItem);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.custom-icon-css
  font-size 30px
  vertical-align middle
  margin-left 10px
  cursor pointer
  &.remove
    color: red
>>>.hide
  .el-upload--picture-card
    display none
</style>
